// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-diy-js": () => import("./../../../src/pages/diy.js" /* webpackChunkName: "component---src-pages-diy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rta-js": () => import("./../../../src/pages/rta.js" /* webpackChunkName: "component---src-pages-rta-js" */),
  "component---src-templates-crate-template-js": () => import("./../../../src/templates/crate-template.js" /* webpackChunkName: "component---src-templates-crate-template-js" */)
}

